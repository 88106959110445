.skill {
  margin: 130px 0 80px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.skill img {
  width: 30%;
}
.skill h3 {
  margin: 40px 0;
  font-size: 35px;
  font-weight: 800;
}
.skill h4 {
  color: #ffffff;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 0;
}

@media (max-width: 1250px) {
  .skill img {
    width: 25%;
  }
  .skill h3 {
    font-size: 25px;
  }
  .skill h4 {
    font-size: 13px;
    margin-top: 5px;
  }
}
@media (max-width: 900px) {
  .skill {
    margin: 70px 0;
  }
  .skill img {
    width: 30%;
  }
  .skill h3 {
    margin: 20px 0;
    font-size: 20px;
  }
  .skill h4 {
    font-size: 10px;
    margin-top: 5px;
  }
}
@media (max-width: 700px) {
  .skill {
    margin: 30px 0;
  }
  .skill img {
    width: 18%;
  }
}
