.nav {
  z-index: 1;
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  background-color: #262626;
  display: flex;
  align-items: center;
}
.iconHeader {
  flex: 2;
  margin-left: 20px;
}
.iconHeader img {
  width: 65px;
}
.navHeader {
  flex: 1.5;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  display: flex;
  font-size: 12px;
  font-weight: 900;
}
.active {
  text-decoration: none;
  color: #262626;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: default;
}
.pending {
  text-decoration: none;
  color: #94d25a;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.pending:hover {
  background-color: grey;
}
.pending:active {
  background-color: #ffffff;
}
.displayMenu {
  display: none;
}
.menuRespondsive {
  display: block;
}
.iconMenuRespondsive {
  position: relative;
}
.innerMenu {
  z-index: 1;
  display: none;
}
.linkMenu {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #94d25a;
  text-decoration: none;
  height: 100px;
  font-weight: 900;
}

@media (max-width: 1500px) {
  .navHeader {
    font-size: 11px;
  }
}
@media (max-width: 1300px) {
  .nav {
    height: 60px;
  }
  .iconHeader img {
    width: 50px;
  }
  .navHeader {
    font-size: 10px;
  }
}
@media (max-width: 1200px) {
  .navHeader {
    font-size: 9px;
  }
}
@media (max-width: 1050px) {
  .nav {
    height: 50px;
  }
  .iconHeader img {
    width: 40px;
  }
  .navHeader {
    font-size: 8px;
  }
}
@media (max-width: 950px) {
  .navHeader {
    font-size: 12px;
  }
  .innerMenu {
    border-left: solid 2px #262626;
    display: block;
    top: 50px;
    right: 0;
    position: fixed;
    background-color: #262626;
    width: 30%;
    height: auto;
  }
  .pending {
    display: none;
    width: 20%;
  }
  .linkMenu:hover {
    background-color: grey;
  }
  .linkMenu:active {
    background-color: #ffffff;
  }
  .pendingResponsive {
    color: #94d25a;
    text-decoration: none;
    text-align: center;
  }
  .displayMenu {
    display: flex;
  }
}
@media (max-width: 700px) {
  .navHeader {
    font-size: 10px;
  }
  .pending {
    display: none;
    width: 25%;
  }
  .displayMenu {
    display: flex;
  }
}
@media (max-width: 500px) {
  .navHeader {
    font-size: 9px;
  }
  .pending {
    display: none;
    width: 30%;
  }
  .displayMenu {
    display: flex;
  }
  .innerMenu {
    width: 35%;
  }
  .linkMenu {
    font-size: 12px;
  }
}
