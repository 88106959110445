.skillSection {
  background-color: #262626;
  width: 100%;
  height: auto;
}
.skillSection h2 {
  text-align: center;
  padding-top: 90px;
}
.loading {
  color: #4e871c;
  width: 100%;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.loading h2 {
  margin-bottom: 50px;
}
.divisionSkill {
  padding: 0 15%;
  display: flex;
}
.sectionRealization h2 {
  text-align: center;
  padding-top: 80px;
}

@media (max-width: 1250px) {
  .skillSection h2 {
    font-size: 40px;
  }
  .sectionRealization h2 {
    font-size: 40px;
  }
}
@media (max-width: 900px) {
  .skillSection h2 {
    font-size: 35px;
    padding-top: 70px;
  }
  .sectionRealization h2 {
    font-size: 35px;
  }
}
@media (max-width: 700px) {
  .skillSection h2 {
    font-size: 40px;
    padding-top: 50px;
  }
  .divisionSkill {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 40px;
    padding-bottom: 40px;
  }
  .sectionRealization h2 {
    font-size: 40px;
  }
}
