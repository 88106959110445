.cardProject {
  display: flex;
  width: 100%;
}
.card {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 30px;
  width: 0;
  height: 600px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
}
.card:hover {
  opacity: 0.4;
}
.card:active {
  opacity: 1;
}
.cardImage {
  width: 100%;
  height: 50%;
  border-radius: 0 0 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.cardImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cardDescription {
  display: flex;
  flex-direction: column;
  padding: 10% 5%;
  width: 90%;
  height: 35%;
}
.cardDescriptionTag {
  flex: 1 50%;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.cardDescriptionTitle {
  flex: 1 50%;
  width: 100%;
  margin-top: 50px;
}
.cardDescriptionTitle h3 {
  font-size: 30px;
  font-weight: 800;
  color: #262626;
}
.modalCrossClose {
  color: #ffffff;
  position: absolute;
  top: 4px;
  right: 10px;
  cursor: pointer;
}
.Modal {
  margin-top: 40px;
  position: absolute;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  width: 80%;
  height: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  border: 3px solid #94d25a;
  padding: 0;
  max-height: 90vh;
  overflow-y: auto;
  overscroll-behavior: contain;
  outline: none;
}
@media (max-width: 1300px) {
  .Modal {
    margin-top: 30px;
  }
}
@media (max-width: 1050px) {
  .Modal {
    margin-top: 25px;
  }
}
@media (max-width: 700px) {
  .ReactModal__Overlay {
    z-index: 2;
  }
  .Modal {
    margin: 0;
    width: 100%;
    max-height: 100vh;
    border: none;
    border-radius: 0;
  }
}
@media (max-width: 550px) {
  .card {
    height: 470px;
  }
}
@media (max-width: 390px) {
  .card {
    height: 420px;
  }
  .cardDescriptionTag {
    display: none;
  }
}

