.footer {
  background-color: #94d25a;
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.footer h2 {
  color: #262626;
  font-size: 30px;
  font-weight: 700;
  margin: 10px;
}
.footerLink {
  text-align: center;
}
.footerLink img {
  width: 60px;
}
.logoGithub {
  margin: 0 40px;
}

@media (max-width: 950px) {
  .footer h2 {
    font-size: 25px;
  }
  .footerLink img {
    width: 45px;
  }
}
@media (max-width: 600px) {
  .footer h2 {
    font-size: 20px;
  }
  .footerLink img {
    width: 35px;
  }
}
